import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

import globalVariables from "../globalVariables"
import Buttonv2 from "../elements/Buttonv2"
import menuData from "./menuData"
import openInNewTab from "../../utils/openInNewTab"
import useWindowSize from "../../customHooks/useWindowSize"
import Logo from "../../images/logo/logo-slap-digital-violet.png"
import BackgroundImg from "../../images/fond-menu-mobile.png"
import CloseIcon from "../../images/icons/close-icon.svg"
import PurpleArrow from "../../images/icons/arrow-purple.svg"
import GreyArrow from "../../images/icons/arrow-grey.svg"
import BlueArrow from "../../images/icons/arrow-blue.svg"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: -4px;
  right: ${props => (props.isBurgerActive ? "0" : "-250px")};
  padding: 0 30px 0 20px;
  width: 100%;
  height: ${props => props.height}px;
  transform: ${props =>
    props.isBurgerActive ? "translateX(0)" : "translateX(100%)"};
  opacity: 1;
  z-index: 10;
  background-color: #eae8eb;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  overflow: hidden;
  &::before {
    content: url(${BackgroundImg});
    position: absolute;
    left: -162px;
    bottom: -10px;
  }
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    display: none;
  }
`

const LogoLink = styled(Link)`
  width: 190px;
`

const WrapperLogo = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${globalVariables.navbarHeightMobile};
  z-index: 1;
`

const WrapperClose = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${props => props.theme.white};
  border: none;
  outline: none !important;
  padding: 0;

  cursor: pointer;
  z-index: 4;
`

const Icon = styled.img`
  width: 30px;
  height: 30px;
`

const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  z-index: 20;
  padding: 80px 0 20px 0;
  color: ${props => props.theme.darkGrey};
  .contactButton {
    width: 100%;
    height: 52px;
    font-size: 22px;
    font-weight: 700;
  }
  .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const WrapperLang = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  a,
  a:visited,
  a:-webkit-any-link {
    color: ${props => props.theme.primaryDarkPurple};
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
    padding-right: 10px;
    &.active {
      color: ${props => props.theme.lightBlue};
    }
  }
`

const WrapperItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;

  .link-menu {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    text-decoration: none;
    color: ${props => props.theme.darkGrey};
    &.purple {
      color: ${props => props.theme.primaryDarkPurple};
    }
    &.blue {
      color: ${props => props.theme.lightBlue};
    }
  }
  .link-submenu-title {
    width: 100%;
    display: block;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    text-decoration: none;
    color: ${props => props.theme.lightBlue};
    margin-bottom: 10px;
  }
  .link-submenu {
    width: 100%;
    display: block;
    font-size: 22px;
    font-weight: 700;
    text-decoration: none;
    color: ${props => props.theme.darkGrey};
  }
`

const WrapperLink = styled.div`
  width: 100%;
  position: relative;
`

const WrapperBack = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
`

const BackArrow = styled.img`
  width: 30px;
  transform: rotate(180deg);
`
const Arrow = styled.img`
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  z-index: -1;
`

const LinkStyled = ({ item, className, icon, handleClick, isUrl, noArrow }) => (
  <WrapperLink onClick={handleClick}>
    <Link to={isUrl && item.url ? item.url : null} className={className}>
      {item.title}
    </Link>
    {!noArrow && <Arrow src={icon} />}
  </WrapperLink>
)

const MenuMobile = ({ handleToggleMenu, isBurgerActive, isEnglish }) => {
  const [step, setStep] = useState(0)
  const [selectedUnivers, setSelectedUnivers] = useState()
  const [selectedExpertise, setSelectedExpertise] = useState()
  const { windowSize } = useWindowSize()
  const menuMobile = menuData.slice(0, -1)

  const goToStep2 = item => {
    setSelectedUnivers(item)
    setStep(2)
  }

  const goToStep3 = item => {
    setSelectedExpertise(item)
    setStep(3)
  }

  return (
    <Wrapper isBurgerActive={isBurgerActive} height={windowSize.height}>
      <WrapperLogo>
        <LogoLink to="/">
          <img src={Logo} alt="Logo SLAP digital" />
        </LogoLink>
        <WrapperClose onClick={handleToggleMenu}>
          <Icon src={CloseIcon} />
        </WrapperClose>
      </WrapperLogo>

      <WrapperContent>
        {step !== 0 && (
          <WrapperBack className="flex" onClick={() => setStep(step - 1)}>
            <BackArrow src={GreyArrow} />
            Retour
          </WrapperBack>
        )}
        <WrapperItems>
          {step === 0 &&
            menuMobile.map((menu, index) =>
              menu.title === "Expertises" ? (
                <LinkStyled
                  key={"menu" + index}
                  item={menu}
                  className="link-menu purple"
                  icon={PurpleArrow}
                  handleClick={() => setStep(1)}
                />
              ) : (
                <Link
                  to={menu.url ?? null}
                  className="link-menu"
                  key={"menu" + index}
                >
                  {menu.title}
                </Link>
              )
            )}
          {step === 1 && (
            <>
              <LinkStyled
                item={menuMobile[1]}
                className="link-submenu-title"
                icon={BlueArrow}
                isUrl
                noArrow={!menuMobile[1]?.url}
              />
              {menuMobile[1].children.slice(0, 6).map((univers, index) => (
                <LinkStyled
                  key={"univers" + index}
                  item={univers}
                  className="link-submenu"
                  icon={GreyArrow}
                  handleClick={() => goToStep2(univers)}
                />
              ))}
            </>
          )}
          {step === 2 && (
            <>
              <LinkStyled
                item={selectedUnivers}
                className="link-submenu-title"
                icon={BlueArrow}
                isUrl
                noArrow={!selectedUnivers?.url}
              />
              {selectedUnivers.children.slice(0, 6).map((expertise, index) => (
                <LinkStyled
                  key={"expertise" + index}
                  item={expertise}
                  className="link-submenu"
                  icon={GreyArrow}
                  handleClick={() => goToStep3(expertise)}
                />
              ))}
            </>
          )}
          {step === 3 && (
            <>
              <LinkStyled
                item={selectedExpertise}
                className="link-submenu-title"
                icon={BlueArrow}
                isUrl
                noArrow={!selectedExpertise?.url}
              />
              {selectedExpertise.children
                .slice(0, 6)
                .map((expertise, index) => (
                  <LinkStyled
                    key={"expertise" + index}
                    item={expertise}
                    className="link-submenu"
                    icon={GreyArrow}
                    isUrl
                  />
                ))}
            </>
          )}
        </WrapperItems>
        <div>
          <WrapperLang>
            <Link to="/" className={!isEnglish ? "active" : null}>
              FR
            </Link>
            <Link to="/en/">EN</Link>
          </WrapperLang>
          <Buttonv2
            content="CONTACTEZ-NOUS"
            style="primaryDarkPurpleBackgroundWhitefont contactButton"
            type="rounded-small"
            icon="rightArrowPurple"
            handleClick={() => openInNewTab(menuData[menuData.length - 1].url)}
          />
        </div>
      </WrapperContent>
    </Wrapper>
  )
}

MenuMobile.propTypes = {
  handleToggleMenu: PropTypes.func,
  isBurgerActive: PropTypes.bool,
}

export default MenuMobile
