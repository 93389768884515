import React from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"

import globalVariables from "../globalVariables"
import DropdownMenu from "../elements/Dropdown/DropdownMenu"
import Buttonv2 from "../elements/Buttonv2"
import SubMenuDesktop from "./SubMenuDesktop"
import Fleche from "../../images/icons/fleche-blanche-v2.svg"

export const Item = styled.li`
  list-style: none;
  margin-bottom: 35px;

  .link-item {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    position: relative;
    z-index: 0;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 15px;
    color: ${props => props.theme.white} !important;
  }
  & .active:not(.contact) {
    color: ${props => props.theme.lightBlue} !important;
  }

  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;

    transition: background-color 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      .link-item:not(.contact) {
        border-radius: 4px;
        background-color: ${props => props.theme.lightPurple};
      }
    }

    .link-item {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding: 18px 14px;
      font-weight: 900;
    }
  }
  .contact {
    width: 160px;
    margin: 0 35px 0 15px;
  }
  .submenu img {
    margin-left: 3rem;
    @media (min-width: ${globalVariables.burgerBreakpoint}) {
      display: none;
    }
  }
`

const Arrow = styled.img`
  margin-left: 5px;
  width: 20px;
  transform: ${props => (props.isOpen ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.2s ease-in-out;
`

const MenuItem = ({ item }) => {
  let isExpertisePathname = false
  if (typeof window !== "undefined") {
    isExpertisePathname = window.location.href.includes("/expertise/")
  }

  return item.title === "Expertises" ? (
    <Item>
      <DropdownMenu
        top="63px"
        left="-418px"
        width="1185px"
        triggerComponent={({ handleClick, isOpen }) => (
          <div onClick={handleClick}>
            <Link
              className={`link-item ${isExpertisePathname ? "active" : ""}`}
              disabled={true}
            >
              {item.title}
              <Arrow src={Fleche} alt="sous-menu expertise" isOpen={isOpen} />
            </Link>
          </div>
        )}
      >
        <SubMenuDesktop />
      </DropdownMenu>
    </Item>
  ) : (
    <Item>
      {item.title === "Contactez-nous" ? (
        <Buttonv2
          content={item.title}
          style="GreenBackgroundDarkPurpleFont contact"
          type="rounded-header"
          isExternalLink
          handleClick={() => navigate(item.url)}
        />
      ) : (
        <Link
          to={`${item.url}/`}
          activeClassName="active"
          className="link-item"
        >
          {item.title}
        </Link>
      )}
    </Item>
  )
}

export default MenuItem
