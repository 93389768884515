import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import theme from "../../theme"
import globalVariables from "../../globalVariables"

const Wrapper = styled.div`
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    margin-bottom: 50px;
  }
`

const Titre = styled.p`
  color: ${theme.white};
  font-size: 25px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0;
`

const Li = styled.li`
  color: ${theme.grey};
  font-family: "source sans pro";
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0;
  line-height: 29px;
  margin-bottom: 0;

  a {
    text-decoration: none;
    &:visited {
      color: ${theme.grey};
    }
  }
`

const Ul = styled.ul`
  list-style: none;
`

const InformationsFooter = () => {
  return (
    <Wrapper>
      <Titre>INFORMATIONS</Titre>
      <Ul>
        <Li>
          <Link to="/agence/">L'agence</Link>
        </Li>
        <Li>
          <Link to="/nos-references/">Nos références</Link>
        </Li>
        <Li>
          <Link to="/recrutement/">Nous rejoindre</Link>
        </Li>
        <Li>
          <Link to="/contact/">Contactez-nous</Link>
        </Li>
        <Li>
          <Link to="/mentions-legales">Mentions légales</Link>
        </Li>
        <Li>
          <Link to="/mentions-legales">Confidentialité</Link>
        </Li>
      </Ul>
    </Wrapper>
  )
}

export default InformationsFooter
