import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import ArrowIcon from "../../images/icons/arrow-purple.svg"

const Card = styled(Link)`
  position: relative;
  display: block;
  width: 226px;
  height: 120px;
  border-bottom: 4px solid ${props => props.theme[props.borderColor]};
  border-radius: 4px;
  background-color: ${props => props.theme.lightGrey};
  padding: 35px 20px;
  text-decoration: none;
`

const Arrow = styled.img`
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
`

const CardMenu = ({ borderColor, title, url }) => {
  return (
    <Card to={url} borderColor={borderColor}>
      {title}
      <Arrow src={ArrowIcon} />
    </Card>
  )
}

CardMenu.propTypes = {
  borderColor: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
}

export default CardMenu
