import { css } from "styled-components"
import { getVariantButtonColors } from "./variant"
import globalVariables from "../../globalVariables"

export const getButtonStyled = props => {
  return css`
    cursor: ${props => (props.disabled ? "default" : "pointer")};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    min-width: 48px;
    border-radius: ${globalVariables.borderRadiusButton};
    text-decoration: none;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
    border: ${globalVariables.borderWidth} solid;
    margin: ${props => (props.margin ? props.margin : "0")};
    opacity: ${props => (props.disabled ? "0.5" : "1")};
    transition: all 0.3s ease;
    &.btn-small {
      height: 37px;
    }
    ${getVariantButtonColors};
    /* @media (min-width: ${globalVariables.minDesktop}) {
      height: 38px;
    }
    @media (min-width: ${globalVariables.bigDesktop}) {
      height: 40px;
    } */
    span.btn-text {
      padding-left: ${props.colors === "link" ? "0" : "4px"};
      padding-right: ${props.colors === "link" ? "0" : "4px"};
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
    }
    span.btn-icon {
      display: flex;
      > div {
        display: flex !important;
      }
    }

    &:focus {
      outline: none;
    }

    /* Material style goes here */
    &.is-material {
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    }

    /* When button on loading stage */
    &.is-loading {
      .btn-text {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  `
}

export default getButtonStyled
