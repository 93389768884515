import React from "react"
import styled from "styled-components"

import globalVariables from "../../globalVariables"

const SectionStyled = styled.section`
  position: relative;
  padding: ${props =>
    props.paddingMobile
      ? props.paddingMobile
      : props.padding
      ? props.padding
      : "45px 0"};
  width: ${props => (props.width ? props.width : null)};
  background-color: ${props =>
    props.color ? props.theme[props.color] : "inherit"};
  margin-top: ${props =>
    props.isfirstBlock || props.isfirstBlockOnMobile
      ? globalVariables.navbarHeightMobile
      : "0"};
  overflow: ${props => (props.overflow ? props.overflow : null)};
  z-index: ${props => (props.zIndex ? props.zIndex : null)};
  .gatsby-image-wrapper {
    height: ${props =>
      props.imageMobileHeight ? props.imageMobileHeight : "100%"};
  }

  @media (min-width: ${globalVariables.minTablet}) {
    padding: ${props => (props.padding ? props.padding : "65px 0")};
    margin-top: ${props => (props.isfirstBlockOnMobile ? "0" : null)};

    .gatsby-image-wrapper {
      height: ${props => (props.imageHeight ? props.imageHeight : "100%")};
    }
    img {
      object-position: ${props =>
        props.objectPosition ? props.objectPosition : "center center"};
    }
  }
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    margin-top: ${props =>
      props.isfirstBlock ? globalVariables.navbarHeight : "0"};
  }
`

const Section = ({
  color,
  padding,
  paddingMobile,
  isfirstBlock,
  isfirstBlockOnMobile,
  imageHeight,
  imageMobileHeight,
  objectPosition,
  id,
  className,
  overflow,
  width,
  zIndex,
  children,
}) => {
  return (
    <SectionStyled
      color={color}
      padding={padding}
      paddingMobile={paddingMobile}
      isfirstBlock={isfirstBlock}
      isfirstBlockOnMobile={isfirstBlockOnMobile}
      imageHeight={imageHeight}
      imageMobileHeight={imageMobileHeight}
      objectPosition={objectPosition}
      id={id}
      className={className}
      overflow={overflow}
      width={width}
      zIndex={zIndex}
    >
      {children}
    </SectionStyled>
  )
}

export default Section
