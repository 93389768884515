import React from "react"

import FlecheBlanche from "../../../images/icons/fleche-blanche.svg"
import FlecheBleue from "../../../images/icons/fleche-bleue.svg"
import FlecheGrise from "../../../images/icons/fleche-grise.svg"
import FlechePleine from "../../../images/icons/flechegauchepleine.svg"

export const NextArrow = props => {
  const { className, style, onClick, withBg } = props
  return (
    <img
      className={className}
      style={{
        transform: "rotate(180deg) translate(0, 50%)",
        height: withBg ? "54px" : "auto",
        ...style,
        width: withBg ? "54px" : "auto",
        zIndex: withBg ? "1" : null,
        right: withBg ? "-4px" : null,
        top: withBg ? "40%" : null,
      }}
      onClick={onClick}
      src={
        props.color
          ? FlecheBleue
          : props.withBg
          ? FlechePleine
          : props.gris
          ? FlecheGrise
          : FlecheBlanche
      }
      alt="prochain"
    />
  )
}

export const PrevArrow = props => {
  const { className, style, onClick, withBg } = props
  return (
    <img
      className={className}
      style={{
        height: withBg ? "54px" : "auto",
        ...style,
        width: withBg ? "54px" : "auto",
        zIndex: withBg ? "1" : null,
        left: withBg ? "-4px" : null,
        top: withBg ? "40%" : null,
      }}
      onClick={onClick}
      src={
        props.color
          ? FlecheBleue
          : props.withBg
          ? FlechePleine
          : props.gris
          ? FlecheGrise
          : FlecheBlanche
      }
      alt="précédent"
    />
  )
}
