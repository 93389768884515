import React, { useEffect, useState, useRef } from "react"
import { CSSTransition } from "react-transition-group"
import styled from "styled-components"
import globalVariables from "../../globalVariables"

const DropdownWrapperStyled = styled.div`
  position: relative;
`

const DropdownContentStyled = styled.div`
  display: block;
  padding: 20px;

  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    width: ${props => (props.width ? props.width : null)};
    background: white;
    border-radius: 4px;
    position: absolute;
    top: ${props => (props.top ? props.top : "auto")};
    left: ${props => (props.left ? props.left : "auto")};
    opacity: 0;
    transform: translateY(-100%);
    transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
    z-index: -1;

    &.inline-dropdown {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 400px;
      a {
        flex: 0 0 33%;
      }
    }
  }
`

export const DropdownMenu = ({
  triggerComponent,
  top,
  left,
  width,
  className,
  children,
}) => {
  const dropdownNode = useRef()
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = e => {
    if (dropdownNode && dropdownNode.current.contains(e.target)) {
      return
    }
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  const triggerElement = Component => {
    return (
      <Component
        isOpen={isOpen}
        handleClick={e => {
          setIsOpen(!isOpen)
        }}
      />
    )
  }

  return (
    <DropdownWrapperStyled ref={dropdownNode}>
      <div>{triggerElement(triggerComponent)}</div>

      <CSSTransition
        in={isOpen}
        timeout={100}
        classNames="dropdown-menu"
        unmountOnExit
      >
        <DropdownContentStyled
          top={top}
          left={left}
          width={width}
          className={className}
        >
          {children}
        </DropdownContentStyled>
      </CSSTransition>
    </DropdownWrapperStyled>
  )
}

export default DropdownMenu
