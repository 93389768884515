import styled, { css } from "styled-components"
import globalVariables from "../../globalVariables"

const ContainerWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
      max-width: none !important;
    `};

  ${props =>
    (props.noGutter &&
      css`
        padding-left: 0;
        padding-right: 0;
      `) ||
    css`
      padding-left: 20px;
      padding-right: 20px;
    `};
  @media (min-width: ${globalVariables.minTablet}) {
    max-width: 750px;
    width: 100%;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    max-width: 970px;
    width: 100%;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    max-width: ${props => props.width || "1200px"};
    width: 100%;
  }
  @media (min-width: ${globalVariables.bigDesktop}) {
    max-width: ${props => props.width || "1400px"};
    width: 100%;
  }
  @media (max-width: ${globalVariables.minTablet}) {
    ${props =>
      props.mobileGutter &&
      css`
        padding-left: 20px;
        padding-right: 20px;
      `};
  }
  &.position-relative {
    position: relative;
  }
`

export default ContainerWrapper
