const theme = {
  primary: "#0cd3cf",
  primaryLight: "#0cd3cf",
  secondary: "#40095d",
  secondaryDark: "#35084d",
  secondaryWithOpacity: "rgba(80, 11, 117, 0.5)",
  secondaryWithOpacity80: "rgba(80, 11, 117, 0.8)",
  white: "#ffffff",
  black: "#000",
  grey: "#aaaaaa",
  greyLighter: "#f1f1f1",
  greyDarker: "#51555d",
  bodyColor: "#3d4051",
  headerColor: "#868a9b",
  error: "#dc3545",
  primaryDarkPurple: "#35084d",
  secondaryDarkPurple: "#40095d",
  purple: "#500B75",
  lightPurple: "#5E3B71",
  lighterPurple: "#75567F",
  green: "#b1f953",
  lightGreen: "#C2FB7A",
  lightBlue: "#0cd3cf",
  darkGrey: "#232323",
  lightGrey: "#efefef",
  greyButton: "#3d4051",
  greyButtonWithHover: "rgba(255, 255, 255, 0.2)",
}

export default theme
