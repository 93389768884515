import React, { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import isEmail from "validator/lib/isEmail"

import Buttonv2 from "../Buttonv2"
import theme from "../../theme"
import globalVariables from "../../globalVariables"

const Section = styled.div`
  margin-bottom: 100px;

  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    margin-bottom: 50px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    flex-direction: column;
  }
`

const Titre1 = styled.p`
  color: ${theme.lightBlue};
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 1.3;
  margin-bottom: 10px;

  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    padding-top: 30px;
  }
`

const Titre2 = styled.p`
  color: ${theme.white};
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.2;
  width: 520px;
  margin-bottom: 0;
  flex-shrink: 0;
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    width: auto;
  }
`

const Form = styled.form`
  margin-bottom: 0;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    flex-direction: column;
    padding-top: 30px;
  }
`

const Input = styled.input`
  background-color: ${theme.white};
  border: none;
  border-radius: 18px;
  color: ${theme.greyButton};
  font-size: 18px;
  font-weight: normal;
  height: 60px;
  line-height: 1.3;
  letter-spacing: 0;
  padding-left: 30px;
  width: 100%;
  flex-grow: 1;
  margin: 0 15px 0 0;

  &::-webkit-input-placeholder {
    opacity: 1;
  }

  &[placeholder] {
    color: ${theme.greyButton};
  }

  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`

const ValidationMessage = styled.span`
  position: absolute;
  bottom: 20px;
  left: 0px;
  font-size: 90%;
  color: ${props => props.theme[props.color]};
  margin-left: 30px;
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    bottom: -35px;
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
`

const Newsletterv2 = ({ locationGTM }) => {
  const [email, setEmail] = useState({
    email: "",
    submit: false,
    sendToSendInBlue: false,
    alreadyExist: false,
  })

  const updateInputData = event => {
    setEmail({ ...email, email: event.target.value })
  }

  const handleSubmit = event => {
    event.preventDefault()

    // on verifie que le format e-mail est valide
    // https://developers.sendinblue.com/docs/synchronise-contact-lists
    if (isEmail(email.email)) {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "newsletter",
          eventCategory: "Inscription newsletter",
          eventAction: "Validation inscription newsletter",
          eventLabel: locationGTM,
        })
      } else {
        window.dataLayer = []
        window.dataLayer.push({
          event: "newsletter",
          eventCategory: "Inscription newsletter",
          eventAction: "Validation inscription newsletter",
          eventLabel: locationGTM,
        })
      }
      axios({
        method: "POST",
        url: "https://api.sendinblue.com/v3/contacts",
        headers: {
          "api-key":
            "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",
          "content-type": "application/json",
          accept: "application/json",
        },
        data: { email: email.email },
      })
        .then(result => {
          setEmail({ email: "", sendToSendInBlue: true, alreadyExist: false })
        })
        .catch(error => {
          setEmail({ ...email, alreadyExist: true, submit: false })
        })
    } else {
      setEmail({ ...email, submit: true, alreadyExist: false })
    }
  }

  return (
    <Section>
      <Titre1>Ne loupez rien de nos actualités !</Titre1>
      <Wrapper>
        <Titre2>Inscrivez-vous à notre newsletter mensuelle</Titre2>
        <Form onSubmit={handleSubmit}>
          <Input
            placeholder="Votre E-mail"
            value={email.email}
            onChange={e => updateInputData(e)}
            name="email"
            required
          />

          <Buttonv2
            content="S'inscrire"
            style="greenBackgroundGreyFont"
            type="rounded-large"
          />
          {!isEmail(email.email) && email.submit && (
            <ValidationMessage color="error">
              Veuillez rentrer un e-mail valide.
            </ValidationMessage>
          )}
          {email.sendToSendInBlue && (
            <ValidationMessage color="white">
              Merci, votre e-mail a bien été enregistré !
            </ValidationMessage>
          )}
          {email.email.length > 0 && email.alreadyExist && (
            <ValidationMessage color="error">
              Cet e-mail est déjà enregistré.
            </ValidationMessage>
          )}
        </Form>
      </Wrapper>
    </Section>
  )
}

export default Newsletterv2
