const menuData = [
  {
    title: "L'agence",
    url: "/agence",
  },
  {
    title: "Expertises",
    children: [
      {
        title: "Acquisition",
        children: [
          {
            title: "Search marketing",
            url: "/expertise/agence-search-marketing/",
            children: [
              {
                title: "SEA - Liens sponsorisés",
                url: "/expertise/agence-sea/",
              },
              {
                title: "SEO - Référencement naturel",
                url: "/expertise/agence-seo/",
              },
              {
                title: "Synergie SEA / SEO",
                url: "/expertise/agence-search-marketing/synergie-seo-sea/",
              },
            ],
          },
          {
            title: "Social Ads",
            url: "/expertise/agence-social-ads/",
            children: [
              {
                title: "Meta - Facebook & Instagram",
                url: "/expertise/social-ads/agence-facebook-instragam-ads/",
              },
              {
                title: "Linkedin",
                url: "/expertise/social-ads/agence-linkedin-ads/",
              },
              {
                title: "Twitter",
                url: "/expertise/social-ads/agence-twitter-ads/",
              },
              {
                title: "Pinterest",
                url: "/expertise/social-ads/agence-pinterest-ads/",
              },
              {
                title: "TikTok",
                url: "/expertise/social-ads/agence-tiktok-ads/",
              },
              {
                title: "Youtube",
                url: "/expertise/social-ads/agence-youtube-ads/",
              },
            ],
          },
        ],
      },
      {
        title: "Data marketing",
        children: [
          {
            title: "Web analytics",
            url: "/expertise/agence-web-analytics/",
            children: [
              {
                title: "Conseils & Stratégie",
                url: "/expertise/agence-web-analytics/conseil-strategie/",
              },
              {
                title: "Atelier KPI",
                url: "/expertise/agence-web-analytics/atelier-kpi/",
              },
              {
                title: "Audit Web Analytics",
                url: "/expertise/agence-web-analytics/audit/",
              },
              {
                title: "Implémentation Web Analytics",
                url: "/expertise/agence-web-analytics/implementation/",
              },
              {
                title: "Plan de taggage & tracking",
                url: "/expertise/agence-web-analytics/plan-taggage-tracking/",
              },
              {
                title: "Conformité RGPD",
                url: "/expertise/agence-web-analytics/conformite-rgpd/",
              },
              {
                title: "Attribution & Contribution",
                url:
                  "/expertise/agence-web-analytics/attribution-contribution/",
              },
            ],
          },
          {
            title: "Conseil data",
            url: "/expertise/agence-data-marketing/",
            children: [
              {
                title: "Conseil & définition du projet Data",
                url: "/expertise/data-marketing/conseil-projet-data/",
              },
              {
                title: "Data engineering",
                url: "/expertise/data-marketing/data-engineering/",
              },
              {
                title: "Dataviz & Reporting",
                url: "/expertise/data-marketing/dataviz-reporting/",
              },
            ],
          },
        ],
      },
      {
        title: "Engagement et conversion",
        children: [
          {
            title: "Expérience utilisateurs",
            url: "/expertise/agence-ux/",
            children: [
              {
                title: "Ateliers stratégie UX",
                url: "/expertise/agence-ux/ateliers-strategie-ux/",
              },
              {
                title: "Définition de personae",
                url: "/expertise/agence-ux/definition-persona/",
              },
              {
                title: "Analyse des parcours utilisateurs",
                url: "/expertise/agence-ux/analyse-parcours-utilisateurs/",
              },
              {
                title: "Audiences & Segmentation",
                url: "/expertise/agence-ux/audience-segmentation/",
              },
              {
                title: "Audit UX",
                url: "/expertise/agence-ux/audit-ux/",
              },
              {
                title: "Wireframes & zoning",
                url: "/expertise/agence-ux/wireframes-zoning/",
              },
              {
                title: "Maquettes graphiques",
                url: "/expertise/agence-ux/maquettes-graphiques/",
              },
            ],
          },
          {
            title: "Optimisation de la conversion",
            url: "/expertise/agence-optimisation-conversion/",
            children: [
              {
                title: "A/B testing",
                url: "/expertise/optimisation-conversion/ab-testing/",
              },
              {
                title: "Heat Map & Session recording",
                url:
                  "/expertise/optimisation-conversion/heat-map-session-recording/",
              },
              {
                title: "Tunnel de conversion",
                url:
                  "/expertise/optimisation-conversion/analyse-tunnel-conversion/",
              },
              {
                title: "Landing pages",
                url: "/expertise/optimisation-conversion/landing-pages/",
              },
              {
                title: "Tests utilisateurs",
                url: "/expertise/optimisation-conversion/tests-utilisateurs/",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "References",
    url: "/nos-references",
  },
  {
    title: "On recrute",
    url: "/recrutement",
  },
  {
    title: "Ressources",
    url: "/blog",
  },
  {
    title: "Contactez-nous",
    url: "/contact",
  },
]

export default menuData
