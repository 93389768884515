import React, { useRef } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Flip from "react-reveal/Flip"
import { useStaticQuery, graphql } from "gatsby"
import { NextArrow, PrevArrow } from "../../elements/ArrowCarousel"

import globalVariables from "../../globalVariables"

const ContainerSlider = styled.div`
  background-color: white;
  width: 100vw;
`

const WrapperSlider = styled.div`
  margin-left: calc(50% - 50vw);
  padding-left: 200px;
  padding-right: 200px;
  width: 100vw;
  @media (max-width: ${globalVariables.maxMobile}) {
    padding-left: 0px;
    padding-right: 0px;
    width: 250px;
    margin: auto;
    .react-reveal {
      animation: none !important;
      opacity: 1 !important;
    }
  }

  img {
    margin: auto;
  }

  .slick-track {
    align-items: center;
    display: flex;
    height: 103px;
  }

  .slick-slide {
    height: auto;
  }
`

const Brand = () => {
  const data = useStaticQuery(
    graphql`
      query {
        easycom: file(relativePath: { eq: "logo-easycom.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 59) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        wands: file(relativePath: { eq: "logo-wands.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 115) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        wondercrush: file(relativePath: { eq: "logo-wondercrush.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 159) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        slapdigital: file(relativePath: { eq: "logo-slapdigital.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 184) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const items = Object.values(data).map(value => value)

  const sliderRef = useRef()

  const settings = {
    dots: false,
    slidesToShow: items.length,
    slidesToScroll: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          autoplay: true,
          infinite: false,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: slick => {
            if (slick === items.length - 1) {
              setTimeout(function() {
                sliderRef.current.slickGoTo(0)
              }, 2000)
            }
          },
        },
      },
    ],
  }

  return (
    <ContainerSlider>
      <WrapperSlider>
        <Slider ref={sliderRef} {...settings}>
          {items.map((item, index) => (
            <Flip key={index} left delay={index * 300}>
              <img src={item.childImageSharp.fluid.src} alt="marque" />
            </Flip>
          ))}
        </Slider>
      </WrapperSlider>
    </ContainerSlider>
  )
}

export default Brand
