import React from "react"
import styled from "styled-components"

import theme from "../../theme"
import globalVariables from "../../globalVariables"

import linkedin from "../../../images/logo/slap_linkedin.svg"
import instagram from "../../../images/logo/slap_instagram.svg"
import facebook from "../../../images/logo/slap_facebook.svg"

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ englishPage }) => englishPage && `margin-top: 50px;`}
`

const Wrapper = styled.div``

const Titre = styled.p`
  color: ${theme.white};
  font-size: 25px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0;
  ${({ englishPage }) => englishPage && `text-align: center;`}
`

const Li = styled.li`
  color: ${theme.grey};
  font-family: "source sans pro";
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0;
  margin-bottom: 0;
  margin-right: 15px;
`

const Ul = styled.ul`
  display: flex;
  list-style: none;
  ${({ englishPage }) => englishPage && `justify-content: center;`}

  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    margin-bottom: 20px;
  }
`

const Img = styled.img`
  height: 41px;
  width: 41px;
`

const P = styled.p`
  color: #7b7b7b;
  margin-bottom: 60px;
`

const SuivreFooter = ({ englishPage }) => {
  const title = englishPage ? "FOLLOW US" : "NOUS SUIVRE"
  return (
    <Section englishPage={englishPage}>
      <Wrapper>
        <Titre englishPage={englishPage}>{title}</Titre>
        <Ul englishPage={englishPage}>
          <Li>
            <a
              href="https://www.linkedin.com/company/slap-digital"
              target="_blanck"
              rel="noopener noreferrer"
            >
              <Img src={linkedin} />
            </a>
          </Li>
          <Li>
            <a
              href="https://www.instagram.com/slap_digital/"
              target="_blanck"
              rel="noopener noreferrer"
            >
              <Img src={instagram} />
            </a>
          </Li>
          <Li>
            <a
              href="https://www.facebook.com/slapdigital/"
              target="_blanck"
              rel="noopener noreferrer"
            >
              <Img src={facebook} />
            </a>
          </Li>
        </Ul>
      </Wrapper>
      <P>© SLAP digital 2023, tous droits réservés</P>
    </Section>
  )
}

export default SuivreFooter
