import React from "react"
import styled from "styled-components"
import globalVariables from "../globalVariables"

import Container from "../elements/Container"
import Newsletterv2 from "../elements/newsletterv2"
import ExpertisesFooter from "../elements/ExpertisesFooter"
import InformationsFooter from "../elements/InformationsFooter"
import SuivreFooter from "../elements/SuivreFooter"
import Brand from "../elements/Brand"

const FooterWrapper = styled.div`
  position: relative;
`

const FooterStyled = styled.footer`
  position: relative;
  background: #333333;
  padding-top: 50px;
  ${({ englishPage }) =>
    englishPage &&
    `display: flex;
    justify-content: center;`}
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 350px;

  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    flex-direction: column;
    height: auto;
  }
`

const Footerv2 = ({ englishPage, locationGTM }) => {
  return (
    <FooterWrapper>
      <FooterStyled englishPage={englishPage}>
        {!englishPage ? (
          <>
            <Container>
              <Newsletterv2 locationGTM={locationGTM} />
              <Wrapper>
                <ExpertisesFooter />
                <InformationsFooter />
                <SuivreFooter englishPage={englishPage} />
              </Wrapper>
            </Container>
            <Brand />
          </>
        ) : (
          <SuivreFooter englishPage={englishPage} />
        )}
      </FooterStyled>
    </FooterWrapper>
  )
}

export default Footerv2
