import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import theme from "../../theme"
import globalVariables from "../../globalVariables"

const Wrapper = styled.div`
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    margin-bottom: 50px;
  }
`

const Titre = styled.p`
  color: ${theme.white};
  font-size: 25px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0;
`

const Li = styled.li`
  color: ${theme.grey};
  font-family: "source sans pro";
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0;
  line-height: 29px;
  margin-bottom: 0;
  a {
    text-decoration: none;
    &:visited {
      color: ${theme.grey};
    }
  }
`

const Ul = styled.ul`
  list-style: none;
`

const ExpertisesFooter = () => {
  return (
    <Wrapper>
      <Titre>NOS EXPERTISES</Titre>
      <Ul>
        <Li>
          <Link to="/expertise/agence-search-marketing/">Search Marketing</Link>
        </Li>
        <Li>
          <Link to="/expertise/agence-social-ads/">Social Ads</Link>
        </Li>
        <Li>
          <Link to="/expertise/agence-data-marketing/">Data Marketing</Link>
        </Li>
        <Li>
          <Link to="/expertise/agence-web-analytics/">Web Analytics</Link>
        </Li>
        <Li>
          <Link to="/expertise/agence-ux/">UX - Expérience Utisateurs</Link>
        </Li>
        <Li>
          <Link to="/expertise/agence-optimisation-conversion/">
            CRO - Optimisation de la conversion
          </Link>
        </Li>
      </Ul>
    </Wrapper>
  )
}

export default ExpertisesFooter
