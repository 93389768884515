import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"

import theme from "../../theme"
import globalVariables from "../../globalVariables"

const StyledButton = styled.button`
  cursor: pointer;
  font-size: 18px;
  position: relative;

  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    margin-bottom: 20px;
    margin-right: 0px;
  }

  ${({ icon }) =>
    icon &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  `}

  &.rounded-small {
    border: none;
    border-radius: 250px;
    font-weight: normal;
    height: 42px;
    line-height: 0.3;
    min-width: 200px;
    padding-right: 10px;
    padding-left: 35px;
  }

  &.rounded-large {
    border: none;
    border-radius: 18px;
    font-weight: 700;
    height: 59px;
    line-height: 0.3;
    width: 184px;

    &:hover {
      transition-duration: 0.3s;
    }

    @media (max-width: ${globalVariables.burgerBreakpoint}) {
      width: 100%;
      max-width: 100vw;
    }
  }

  &.rounded-header {
    border-radius: 10px;
    height: 53px;
    line-height: 0.3;
    width: 245px;
    &:hover {
      transition-duration: 0.3s;
    }
    @media (max-width: ${globalVariables.burgerBreakpoint}) {
      width: 100%;
    }
  }

  &.rounded-filter {
    border: none;
    border-radius: 4px;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    height: 35px;
    justify-content: center;
    align-items: center;
    line-height: 0.3;
    min-width: 198px;
    text-transform: uppercase;
  }

  &.greenBackgroundGreyFont {
    background-color: ${theme.green};
    color: ${theme.greyButton};
  }

  &.lightblueBackgroundWhiteFont {
    background-color: ${theme.lightBlue};
    border: 2px solid ${theme.lightBlue};
    color: white;
  }

  &.primaryDarkPurpleBackgroundWhitefont {
    background-color: ${theme.primaryDarkPurple};
    color: white;
  }

  &.primaryDarkPurpleBackgroundLightBlueFont {
    background-color: ${theme.secondaryDarkPurple};
    border: 2px solid ${theme.lightBlue};
    color: ${theme.lightBlue};
    font-weight: 400;
    &:hover {
      background-color: ${theme.lighterPurple};
    }
  }

  &.GreenBackgroundDarkPurpleFont {
    background-color: ${theme.green};
    border: none;
    color: ${theme.primaryDarkPurple};
    font-weight: 700;
    &:hover {
      background-color: ${theme.lightGreen};
      color: ${theme.lighterPurple};
    }
  }

  &.whiteBackgroundlightBlueFont {
    background-color: white;
    border: 2px solid ${theme.lightBlue};
    color: ${theme.lightBlue};
    text-transform: uppercase;
    font-weight: 900;
  }
`

const StyledRightArrow = styled.img`
  height: 32px;
`

const StyledLeftArrow = styled.img`
  height: 14px;
  left: 10px;
  position: absolute;
`

const AnchorStyled = styled(AnchorLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    margin-bottom: 20px;
    margin-right: 0px;
  }

  &.rounded-header {
    border-radius: 10px;
    height: 53px;
    line-height: 0.3;
    width: 245px;
    &:hover {
      transition-duration: 0.3s;
    }
    @media (max-width: ${globalVariables.burgerBreakpoint}) {
      width: 100%;
    }
  }

  &.primaryDarkPurpleBackgroundLightBlueFont {
    background-color: ${theme.secondaryDarkPurple};
    border: 2px solid ${theme.lightBlue};
    color: ${theme.lightBlue};
    font-weight: 400;
    &:hover {
      background-color: ${theme.lighterPurple};
    }
  }

  &.GreenBackgroundDarkPurpleFont {
    background-color: ${theme.green};
    border: none;
    color: ${theme.primaryDarkPurple};
    font-weight: 700;
    &:hover {
      background-color: ${theme.lightGreen};
      color: ${theme.lighterPurple};
    }
  }
`

const Buttonv2 = ({
  content,
  iconLeft,
  iconRight,
  style = "",
  type,
  handleClick,
  anchor,
  offset,
}) =>
  anchor ? (
    <AnchorStyled
      href={"#" + anchor}
      offset={offset}
      className={`${type} ${style}`}
    >
      {content}
    </AnchorStyled>
  ) : (
    <StyledButton
      className={`${type} ${style}`}
      icon={iconLeft || iconRight}
      onClick={handleClick}
    >
      {iconLeft && <StyledLeftArrow src={iconLeft} alt="icone left" />}
      {content}
      {iconRight && <StyledRightArrow src={iconRight} alt="icone right" />}
    </StyledButton>
  )

Buttonv2.propTypes = {
  content: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  style: PropTypes.string,
  type: PropTypes.string,
  handleClick: PropTypes.func,
  anchor: PropTypes.string,
  offset: PropTypes.number,
}

export default Buttonv2
