const globalVariables = {
  borderRadius: "0px",
  borderRadiusButton: "10px",
  borderWidth: "2px",
  navbarHeight: "107px",
  navbarHeightMobile: "107px",
  maxMobile: "767px",
  minTablet: "768px",
  maxTablet: "1023px",
  minDesktop: "1024px",
  medDesktop: "1220px",
  bigDesktop: "1900px",
  burgerBreakpoint: "1210px",
  opacityText: 0.8,
  heightFilter: "70px",
  heightFilterMobileReferences: "96px",
  heightFilterMobileBlog: "221px",
  marginTopContainer: "100px",
}

export default globalVariables
