import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import globalVariables from "../globalVariables"
import MenuItem from "./MenuItem"
import MenuMobile from "./MenuMobile"
import DropdownMenu from "../elements/Dropdown/DropdownMenu"
import Fleche from "../../images/icons/fleche-blanche-v2.svg"
import LogoBlanc from "../../images/logo/logo-slap-digital-blanc.png"
import menuData from "./menuData"

const Header = styled.header`
  position: fixed;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 3;
  background-color: transparent;
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    width: 1200px;

    /* border-radius: 20px; */
  }
`

const Nav = styled.nav`
  background-color: ${props => props.theme.primaryDarkPurple};
  transition: all 0.2s ease-in-out;
  border-radius: 20px;
  margin-right: 10px;
  margin-left: 10px;
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    margin-right: 0;
    margin-left: 0;
  }
`

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 20px;
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    padding-left: 35px;
    padding-right: 40px;
  }
`

const WrapperBurger = styled.button`
  display: ${props => (props.isBurgerActive ? "none" : "block")};
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${props => props.theme.lightPurple};
  border: none;
  outline: none !important;
  padding: 0;
  cursor: pointer;
  z-index: 4;
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    display: none;
  }
`

const BurgerBar = styled.span`
  display: block;
  height: 2px;
  background-color: ${props => props.theme.white};
  border-radius: 2px;
  margin: 8px auto;
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: 1px solid ${props => "transparent"};
  width: 30px;
`

const WrapperNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: ${globalVariables.navbarHeightMobile};

  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    min-height: ${globalVariables.navbarHeight};
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  z-index: 1;
  width: 190px;
`

const MenuDesktop = styled.ul`
  display: none;
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    flex-direction: row;
    display: flex;
    align-items: center;
  }
`

const Lang = styled.div`
  display: none;
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    position: relative;
    display: block;
    margin-bottom: 0;
    cursor: pointer;
    font-weight: 900;
    color: ${props => props.theme.white};
    img {
      transition: all 0.2s ease-in-out;
      transform: ${props => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
    }
  }
`

const Arrow = styled.img`
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 20px;
`

const DropdownLang = styled(Link)`
  text-decoration: none;
  font-weight: 900;
  color: ${props => props.theme.primaryDarkPurple} !important;
  &:hover {
    color: ${props => props.theme.lightBlue} !important;
  }
`

const Navbar = ({ isBurgerActive, handleToggleMenu, isEnglish }) => {
  return (
    <Header>
      <Nav>
        <Container>
          <WrapperNav>
            <LinkStyled to="/">
              <img src={LogoBlanc} alt="Logo SLAP digital" />
            </LinkStyled>
            {!isEnglish && (
              <>
                <WrapperBurger
                  onClick={handleToggleMenu}
                  isBurgerActive={isBurgerActive}
                >
                  <BurgerBar />
                  <BurgerBar />
                  <BurgerBar />
                </WrapperBurger>
                <MenuMobile
                  handleToggleMenu={handleToggleMenu}
                  isBurgerActive={isBurgerActive}
                  isEnglish={isEnglish}
                />
                <MenuDesktop>
                  {menuData.map((item, index) => (
                    <MenuItem key={"item" + index} item={item} />
                  ))}
                  <DropdownMenu
                    top="63px"
                    left="-20px"
                    width="90px"
                    triggerComponent={({ handleClick, isOpen }) => (
                      <Lang onClick={handleClick} isOpen={isOpen}>
                        <span>FR</span>
                        <Arrow src={Fleche} alt="menu langue" />
                      </Lang>
                    )}
                  >
                    <DropdownLang to="/en/">EN</DropdownLang>
                  </DropdownMenu>
                </MenuDesktop>
              </>
            )}
          </WrapperNav>
        </Container>
      </Nav>
    </Header>
  )
}

Navbar.propTypes = {
  handleToggleMenu: PropTypes.func,
  isBurgerActive: PropTypes.bool,
  isEnglish: PropTypes.bool,
}

export default Navbar
