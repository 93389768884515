import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Buttonv2 from "../elements/Buttonv2"
import menuData from "./menuData"
import CardMenu from "./CardMenu"

const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  cursor: default;
`

const WrapperUnivers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  padding: 20px 20px 16px 20px;
  cursor: default;
`

const Univers = styled.div`
  flex: 0 0 auto;
  .universButton {
    width: 250px;
    cursor: default;
  }

  .link-expertise {
    display: block;
    color: ${props => props.theme.primaryDarkPurple};
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 5px;
    text-decoration: none;
  }
  .link-prestation {
    display: block;
    color: ${props => props.theme.headerColor};
    line-height: 24px;
    text-decoration: none;
  }
`

const Cards = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding-right: 5px;
  gap: 20px;
`

const Title = styled.span`
  font-size: 20px;
  color: ${props => props.theme.secondaryDarkPurple};
  bold {
    display: block;
    font-weight: 900;
  }
`

const SubMenuDesktop = () => {
  const subMenu = menuData[1].children

  return (
    <Wrapper>
      <WrapperUnivers>
        {subMenu.map(univers => (
          <Univers>
            <Buttonv2
              content={univers.title}
              style="whiteBackgroundlightBlueFont universButton"
              type="rounded-filter"
            />
            {univers.children.map(expertise => (
              <>
                <Link to={expertise.url ?? null} className="link-expertise">
                  {expertise.title}
                </Link>
                {expertise.children.map(prestation => (
                  <Link to={prestation.url ?? null} className="link-prestation">
                    {prestation.title}
                  </Link>
                ))}
              </>
            ))}
          </Univers>
        ))}
      </WrapperUnivers>
      <Cards>
        <CardMenu
          borderColor="secondaryDarkPurple"
          url={menuData[0].url}
          title={
            <Title>
              Découvrez <bold>SLAP digital</bold>
            </Title>
          }
        />
        <CardMenu
          borderColor="green"
          url={menuData[2].url}
          title={
            <Title>
              Nos <bold>Etudes de cas</bold>
            </Title>
          }
        />
        <CardMenu
          borderColor="lightBlue"
          url={menuData[4].url}
          title={
            <Title>
              Nos <bold>Publications</bold>
            </Title>
          }
        />
      </Cards>
    </Wrapper>
  )
}

export default SubMenuDesktop
